import validate from "/nuxtapp/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_floating-vue@5.2.2_unocss@0.59.2_vite@5.2.8/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/nuxtapp/middleware/auth.global.js";
import restrict_45routes_45global from "/nuxtapp/middleware/restrictRoutes.global.js";
import manifest_45route_45rule from "/nuxtapp/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_floating-vue@5.2.2_unocss@0.59.2_vite@5.2.8/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  restrict_45routes_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}