import revive_payload_client_4GcNwL6PMi from "/nuxtapp/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_floating-vue@5.2.2_unocss@0.59.2_vite@5.2.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_j4p2POMx79 from "/nuxtapp/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_floating-vue@5.2.2_unocss@0.59.2_vite@5.2.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_bQb4MeUDpd from "/nuxtapp/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_floating-vue@5.2.2_unocss@0.59.2_vite@5.2.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_T8SwpwOZX0 from "/nuxtapp/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_floating-vue@5.2.2_unocss@0.59.2_vite@5.2.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_xDee3gQ6lN from "/nuxtapp/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_floating-vue@5.2.2_unocss@0.59.2_vite@5.2.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_1Iov6EnbNa from "/nuxtapp/node_modules/.pnpm/@pinia+nuxt@0.5.1_vue@3.4.21/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/nuxtapp/.nuxt/components.plugin.mjs";
import prefetch_client_TicWSv5cYr from "/nuxtapp/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_floating-vue@5.2.2_unocss@0.59.2_vite@5.2.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_bnl54XsQHt from "/nuxtapp/node_modules/.pnpm/nuxt-bugsnag@7.4.0-beta.0_vue-router@4.3.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import plugin_QUJKl7CH1E from "/nuxtapp/node_modules/.pnpm/nuxt-directus@5.6.1/node_modules/nuxt-directus/dist/runtime/plugin.mjs";
import chunk_reload_client_8ZQ9DquJUX from "/nuxtapp/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_floating-vue@5.2.2_unocss@0.59.2_vite@5.2.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import initAuth_jCn5ZSEkR1 from "/nuxtapp/plugins/initAuth.js";
export default [
  revive_payload_client_4GcNwL6PMi,
  unhead_j4p2POMx79,
  router_bQb4MeUDpd,
  payload_client_T8SwpwOZX0,
  check_outdated_build_client_xDee3gQ6lN,
  plugin_vue3_1Iov6EnbNa,
  components_plugin_KR1HBZs4kY,
  prefetch_client_TicWSv5cYr,
  plugin_bnl54XsQHt,
  plugin_QUJKl7CH1E,
  chunk_reload_client_8ZQ9DquJUX,
  initAuth_jCn5ZSEkR1
]