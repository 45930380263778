import { defineStore } from 'pinia'

export const useLoaderStore = defineStore('loaderStore', {
    state: () => ({
        loading: false,
    }),
    getters: {
        isLoading() {
            return this.loading
        }
    },
    actions: {
        setLoading(value) {
            this.loading = value
        },
    },
})
