import { defineStore } from 'pinia'
import { useRuntimeConfig } from '#app'
import { useModalsStore } from './useModalsStore'

export const useAuthStore = defineStore('authStore', {
  state: () => ({
    isLogged: false,
    user: {},
  }),
  getters: {
    isAuthenticated() {
      return this.isLogged
    },
    twitterProfile() {
      return this.user
    },
    twitterPhoto() {
      return this.user?.photos[0]?.value
    },
    twitterNickName() {
      return this.user?.username
    },
    otherPage() {
      return this.user?.directusUser?.other_page
    },
    discordId() {
      return this.user?.directusUser?.discord_id
    },
    theRailCode(){
      return this.user?.directusUser?.the_rail_code
    }
  },
  actions: {
    login() {
      const redirectUrl = `${useRuntimeConfig().public.apiLogicUrl}/auth/twitter`
      window.location.href = redirectUrl
    },
    logout() {
      window.location.href = useRuntimeConfig().public.apiLogicUrl + '/auth/logout'
    },
    async getTwitterProfile() {
      try {
        const response = await $fetch('/api/auth/user', {
          credentials: 'include'
        })

        if (response) {
          this.isLogged = true
          this.user = response
        }
      } catch (error) {
        if (error && error.data && error.data.message === 'Unauthorized') {
          this.isLogged = false
          this.user = {}
        }
        console.error('Error fetching user:', error)
      }
    },
    async getUserLikes() {
      try {
        const response = await $fetch('/api/claims/liked/tweets', {
          credentials: 'include'
        })
        if (response) {
          console.log(response)
        }
      } catch (error) {
        console.error('Error fetching likes:', error)
      }
    },
    updateUserOtherPage(otherPage) {
      this.user.directusUser.other_page = null
      this.user.directusUser.other_page = otherPage
    },
    updateUserDiscordId(discord_id) {
      this.user.directusUser.discord_id = discord_id
    }
  },
})
