
import { defineStore } from 'pinia'

export const useModalsStore = defineStore('modalsStore', {
  state: () => ({
    isOpen: false,
    modalId: null,
    modalPayload: null,
    callback:null
  }),
  actions: {
    openModal(modalId, payload,callback) {
      const WELCOME_MODAL_KEY = 'modal-welcome'
      if (modalId === 'WELCOME' && localStorage.getItem(WELCOME_MODAL_KEY)) {
        return
      }
      if (modalId === 'WELCOME') {
        localStorage.setItem(WELCOME_MODAL_KEY, 'true')
      }
      if(callback){
        this.callback = callback
      }
      // Open the modal
      this.isOpen = true
      this.modalId = modalId
      this.modalPayload = payload
    },
    closeModal() { 
      this.isOpen = false
      this.modalId = null
      this.modalPayload = null
      if(this.callback){
        this.callback = null
      }
    },
  },
})
