<template>
  <transition name="fade" mode="out-in">
    <div
      v-show="isLoading"
      class="w-full h-full absolute bg-black flex items-center justify-center z-50 top-0 left-0"
    >
      <img
        src="~/assets/gif/gif-logo-albarra.gif"
        alt="animation logo albarra"
        class="w-56 md:w-64 aspect-square animate-pulse"
      />
    </div>
  </transition>
</template>

<script setup>
import { computed } from 'vue';
import { useLoaderStore } from '~/stores/useLoaderStore';

const useLoader = useLoaderStore(); 
const isLoading = computed(() => useLoader.isLoading);
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
