import { defineStore } from 'pinia'
import { useAuthStore } from './useAuthStore'
import { useLoaderStore } from './useLoaderStore'
import { useModalsStore } from './useModalsStore'

function formatDateToISO(date) {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`

  return formattedDate
}

export const cmsStore = defineStore('cmsStore', {
  state: () => ({
    logsTotal: 0,
    notifications: [],
    haveNewNotifications: false,
    bonusQuests: [],
    completedBonusQuests: [],
    leaderboard: [],
    badges: [],
    fromPage: null,
    app: {
      music: null,
    },
  }),
  getters: {
    getLogsTotal() {
      return this.logsTotal
    },
    getNotifications() {
      return this.notifications
    },
    getClaimBadgeNotifications() {
      return this.notifications.filter((n) => n.badge_id && n.readed === false)
    },
    getLeaderboard() {
      return this.leaderboard
    },
    getBonusQuests() {
      return this.bonusQuests
    },
    hasClaimBadgeNotifications() {
      return this.notifications.some((n) => n.badge_id && n.readed === false)
    },
    hasApeCoinOnBadges() {
      return this.badges.some((b) => b.apecoin) || false
    },
    hasJoinDiscordBonusQuestCompleted() {
      return this.completedBonusQuests.some((bq) => bq.bonus_id === 1)
    },
    getFromPage() {
      if (!this.fromPage) {
        const fromPage = localStorage.getItem('fromPage')
        if (fromPage) {
          this.fromPage = fromPage
        }
      }
      return this.fromPage
    }
  },
  actions: {
    async get(collection, params) {
      try {
        const path = `/api/cms/items/${collection}`
        const response = await $fetch(path, {
          params,
          credentials: 'include',
        })
        if (!response) {
          console.error(`Error fetching ${collection}:`, error.value)
        }
        return response
      } catch (error) {
        if (error.response.statusText === 'Unauthorized') {
          const modalStore = useModalsStore()
          modalStore.openModal('SESSION_EXPIRED')
        }
        console.error(`Error fetching ${collection}:`, error)
      }
    },
    async loadApp() {
      const result = await this.get('app', {})
      this.app.music = result.data[0].music
    },
    async loadNotifications() {
      const auth = useAuthStore()
      if (!auth.isAuthenticated) return
      const params = {
        filter: {
          user_id: auth.user.directusUser.id,
        },
      }
      const result = await this.get('notifications', params)

      this.notifications = result.data
        .sort((a, b) => new Date(a.date_created) - new Date(b.date_created))
        .reverse()
      this.checkNewNotifications()
    },

    async readNotification(notificationId) {
      if (!notificationId) return
      const auth = useAuthStore()
      if (!auth.isAuthenticated) return
      let url =
        useRuntimeConfig().public.apiLogicUrl +
        '/user/readNotification/' +
        notificationId
      const response = await $fetch(url, {
        method: 'PUT',
        credentials: 'include',
      })
      if (!response) {
        console.error(`Error reading notification:`)
      }
      this.loadNotifications()
    },

    async loadBonusQuests() {
      const useLoader = useLoaderStore()
      if (this.bonusQuests.length === 0) {
        useLoader.setLoading(true)
      }
      const params = {
        filter: {
          status: {
            _eq: 'published',
          },
          publication_date: {
            _lt: formatDateToISO(new Date()),
          },
        },
      }
      const bonusQuestsItems = await this.get('bonus_quests', params)
      this.bonusQuests = bonusQuestsItems.data.map((bq) => {
        return {
          ...bq,
          claim: false,
          verify: false,
          readyToClaim: false,
        }
      })
      useLoader.setLoading(false)
    },

    async loadCompletedBonusQuests() {
      const auth = useAuthStore()
      if (!auth.user.directusUser) return
      const params = {
        filter: {
          user_id: auth.user.directusUser.id,
        },
      }
      const result = await this.get('bonus_quests_directus_users', params)

      this.completedBonusQuests = result.data
      this.bonusQuests = this.bonusQuests.map((bq) => {
        const completed = this.completedBonusQuests.find(
          (cbq) => cbq.bonus_id === bq.id,
        )
        if (bq.type === 'follow_discord' && auth.discordId && !completed) {
          return {
            ...bq,
            claim: false,
            verify: false,
            readyToClaim: true,
          }
        }
        if (!completed) {
          return {
            ...bq,
            claim: false,
            verify: false,
            readyToClaim: false,
          }
        }
        return {
          ...bq,
          claim: completed.status === 'claim_completed',
          verify: completed.status === 'claimed_not_repit',
          readyToClaim: false,
        }
      })
      this.bonusQuests = this.bonusQuests.filter(bq => (bq.fixed && !bq.claim) || (!bq.fixed))
    },

    async loadLeaderboard() {
      const useLoader = useLoaderStore()
      if (this.leaderboard.length === 0) {
        useLoader.setLoading(true)
      }
      const params = {
        fields: '*,user.twitter_name_id,user.other_page',
      }
      const result = await this.get('leaderborad', params)
      this.leaderboard = result.data
      console.log('Leaderboard:', this.leaderboard.length)
      useLoader.setLoading(false)
    },
    async loadBadges() {
      const result = await this.get('badges')
      this.badges = result.data
      return this.badges
    },
    async loadUserLogs() {
      const auth = useAuthStore()
      if (!auth.isAuthenticated) return
      const userId = auth.user.directusUser.id
      const path = `/api/user/${userId}/logs`
      const response = await $fetch(path, {
        credentials: 'include',
      })
      if (!response) {
        console.error(`Error fetching user logs:`, error.value)
      }
      this.logsTotal = response.total
    },

    async userHasGotAxedBadge() {
      const auth = useAuthStore()
      if (!auth.isAuthenticated) return
      const userId = auth.user.directusUser.id
      const path = `/api/user/${userId}/badge-axe`
      const response = await $fetch(path, {
        credentials: 'include',
      })
      if (!response) {
        console.error(`Error fetching badge axe:`, error.value)
      }
      const GOT_AXED_BADGE_ID = 8
      const notification = this.notifications.find(
        (n) => n.badge_id === GOT_AXED_BADGE_ID,
      )
      await this.readNotification(notification.id)
      return response
    },

    async userHasOtherPageBadge() {
      const auth = useAuthStore()
      if (!auth.isAuthenticated) return
      const userId = auth.user.directusUser.id
      const path = `/api/user/${userId}/badge-other-page`
      const response = await $fetch(path, {
        credentials: 'include',
      })
      if (!response) {
        console.error(`Error fetching badge other page:`, error.value)
      }
      const OTHER_PAGE_BADGE_ID = 47
      const notification = this.notifications.find(
        (n) => n.badge_id === OTHER_PAGE_BADGE_ID,
      )
      await this.readNotification(notification.id)
      return response
    },

    async userHasWoodCollectorBadge() {
      const auth = useAuthStore()
      if (!auth.isAuthenticated) return
      const userId = auth.user.directusUser.id
      const path = `/api/user/${userId}/badge-wood-collector`
      const response = await $fetch(path, {
        credentials: 'include',
      })
      if (!response) {
        console.error(`Error fetching badge other page:`, error.value)
      }
      const WOOD_COLLECTOR_BADGE_ID = 48
      const notification = this.notifications.find(
        (n) => n.badge_id === WOOD_COLLECTOR_BADGE_ID,
      )
      await this.readNotification(notification.id)
      return response
    },

    async userHasBadge(requested_badge_id) {
      const auth = useAuthStore()
      if (!auth.isAuthenticated) return
      const userId = auth.user.directusUser.id
      const path = `/api/user/${userId}/badge/${requested_badge_id}`
      const response = await $fetch(path, {
        credentials: 'include',
      })
      if (!response) {
        console.error(`Error fetching badge other page:`, error.value)
      }
      if (response.claimWithNotification) {
        const notification = this.notifications.find(
          (n) => n.badge_id === requested_badge_id,
        )
        await this.readNotification(notification.id)
      }
      return response
    },

    async updateNotifications() {
      await this.loadNotifications()
      await this.loadUserLogs()
    },

    checkNewNotifications() {
      let noReadedNotifications = this.notifications.filter(
        (notification) => notification.readed === false,
      )
      if (noReadedNotifications.length > 0) {
        this.haveNewNotifications = true
      } else {
        this.haveNewNotifications = false
      }
    },
    setFromPage(fromPage) {
      this.fromPage = fromPage
    }
  },
})
