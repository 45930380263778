import { default as _91slug_93Qa6TrQQ3RsMeta } from "/nuxtapp/pages/[slug].vue?macro=true";
import { default as aboutICsza733YuMeta } from "/nuxtapp/pages/about.vue?macro=true";
import { default as badgesCAaYWqRvTuMeta } from "/nuxtapp/pages/badges.vue?macro=true";
import { default as index7Y3ALvkP9SMeta } from "/nuxtapp/pages/index.vue?macro=true";
import { default as leaderboardcHTLsQjNQOMeta } from "/nuxtapp/pages/leaderboard.vue?macro=true";
import { default as questsZGiJAW5FBkMeta } from "/nuxtapp/pages/quests.vue?macro=true";
import { default as verifyIdK4CeKS6ZsGMeta } from "/nuxtapp/pages/verifyId.vue?macro=true";
export default [
  {
    name: _91slug_93Qa6TrQQ3RsMeta?.name ?? "slug",
    path: _91slug_93Qa6TrQQ3RsMeta?.path ?? "/:slug()",
    meta: _91slug_93Qa6TrQQ3RsMeta || {},
    alias: _91slug_93Qa6TrQQ3RsMeta?.alias || [],
    redirect: _91slug_93Qa6TrQQ3RsMeta?.redirect,
    component: () => import("/nuxtapp/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: aboutICsza733YuMeta?.name ?? "about",
    path: aboutICsza733YuMeta?.path ?? "/about",
    meta: aboutICsza733YuMeta || {},
    alias: aboutICsza733YuMeta?.alias || [],
    redirect: aboutICsza733YuMeta?.redirect,
    component: () => import("/nuxtapp/pages/about.vue").then(m => m.default || m)
  },
  {
    name: badgesCAaYWqRvTuMeta?.name ?? "badges",
    path: badgesCAaYWqRvTuMeta?.path ?? "/badges",
    meta: badgesCAaYWqRvTuMeta || {},
    alias: badgesCAaYWqRvTuMeta?.alias || [],
    redirect: badgesCAaYWqRvTuMeta?.redirect,
    component: () => import("/nuxtapp/pages/badges.vue").then(m => m.default || m)
  },
  {
    name: index7Y3ALvkP9SMeta?.name ?? "index",
    path: index7Y3ALvkP9SMeta?.path ?? "/",
    meta: index7Y3ALvkP9SMeta || {},
    alias: index7Y3ALvkP9SMeta?.alias || [],
    redirect: index7Y3ALvkP9SMeta?.redirect,
    component: () => import("/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: leaderboardcHTLsQjNQOMeta?.name ?? "leaderboard",
    path: leaderboardcHTLsQjNQOMeta?.path ?? "/leaderboard",
    meta: leaderboardcHTLsQjNQOMeta || {},
    alias: leaderboardcHTLsQjNQOMeta?.alias || [],
    redirect: leaderboardcHTLsQjNQOMeta?.redirect,
    component: () => import("/nuxtapp/pages/leaderboard.vue").then(m => m.default || m)
  },
  {
    name: questsZGiJAW5FBkMeta?.name ?? "quests",
    path: questsZGiJAW5FBkMeta?.path ?? "/quests",
    meta: questsZGiJAW5FBkMeta || {},
    alias: questsZGiJAW5FBkMeta?.alias || [],
    redirect: questsZGiJAW5FBkMeta?.redirect,
    component: () => import("/nuxtapp/pages/quests.vue").then(m => m.default || m)
  },
  {
    name: verifyIdK4CeKS6ZsGMeta?.name ?? "verifyId",
    path: verifyIdK4CeKS6ZsGMeta?.path ?? "/verifyId",
    meta: verifyIdK4CeKS6ZsGMeta || {},
    alias: verifyIdK4CeKS6ZsGMeta?.alias || [],
    redirect: verifyIdK4CeKS6ZsGMeta?.redirect,
    component: () => import("/nuxtapp/pages/verifyId.vue").then(m => m.default || m)
  }
]